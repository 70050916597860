@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');

.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed00;
}

.scrollbar::-webkit-scrollbar-thumb {
  /* background: #e0cbcb; */
  background: #cbd5e1;
  border-radius: 100vh;
  border: 1px solid #f6f7ed;
}
.scrollbarSmall::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollbarSmall::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed00;
}

.scrollbarSmall::-webkit-scrollbar-thumb {
  /* background: #e0cbcb; */
  background: #cbd5e1;
  border-radius: 100vh;
  border: 2px solid #f6f7ed;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed00;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    /* background: #e0cbcb; */
    background: #cbd5e1;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  /* .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #10b981;
  } */

  .grad {
    background-image: linear-gradient(-225deg, #3584a7 0%, #473b7b 100%);
  }
  /* .grad {
    background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
  } */

  /* .grad {
    background-image: linear-gradient(to right, #5d4157 0%, #91ada0 100%);
  } */
}

body {
  font-family: 'Baloo 2', sans-serif;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.slick-slide > div {
  margin: 0 5px;
}

/* DISABLE ARROWS FROM INPUT TYPE NUMBER  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
