#filter-menu {
  border: 1px solid #ddd;
  box-shadow: 0px 3px 6px #00000029;
}

.filter-icon {
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.done-button-container {
  display: flex;
  justify-content: right;
  width: 100%;
}
/* .done-button-container:nth-child(2){
  background-color: #16a34a!important;
} */
