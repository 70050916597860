@import 'themes-vars';
@import 'plugins/plugins';

/* breadcrumb */
.link-breadcrumb {
  font-size: 0.75rem;
  display: flex;
  text-decoration: none;
}

/* card */

.card-header {
  font-weight: 600 !important;
  font-size: 15px;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
}
